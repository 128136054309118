<template>
    <svg
        width="48px"
        height="32px"
        viewBox="0 0 48 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M0,12 C-7.99137971e-16,5.47454326 5.20854437,0.165607248 11.6950252,0.00379985776 L12,0 L491,0 C497.525457,-1.19870696e-15 502.834393,5.20854437 502.9962,11.6950252 L503,12 L503,129 C503,135.525457 497.791456,140.834393 491.304975,140.9962 L491,141 L12,141 L11.6950252,140.9962 C5.20854437,140.834393 0,135.525457 0,129 L0,12 Z"
                id="path-1"
            ></path>
            <path
                d="M0,12 C-7.99137971e-16,5.47454326 5.20854437,0.165607248 11.6950252,0.00379985776 L12,0 L491,0 C497.525457,-1.19870696e-15 502.834393,5.20854437 502.9962,11.6950252 L503,12 L503,129 C503,135.525457 497.791456,140.834393 491.304975,140.9962 L491,141 L12,141 L11.6950252,140.9962 C5.20854437,140.834393 0,135.525457 0,129 L0,12 Z"
                id="path-3"
            ></path>
            <radialGradient
                cx="22.357%"
                cy="8.811%"
                fx="22.357%"
                fy="8.811%"
                r="113.202%"
                gradientTransform="translate(0.223570,0.088110),scale(1.000000,0.874516),translate(-0.223570,-0.088110)"
                id="radialGradient-5"
            >
                <stop stop-color="#FFCC00" offset="0%"></stop>
                <stop stop-color="#FFC800" offset="9.157%"></stop>
                <stop stop-color="#FFBD00" offset="17.39%"></stop>
                <stop stop-color="#FFAB00" offset="25.28%"></stop>
                <stop stop-color="#FF9100" offset="32.95%"></stop>
                <stop stop-color="#FF7000" offset="40.46%"></stop>
                <stop stop-color="#FF4700" offset="47.86%"></stop>
                <stop stop-color="#FF1800" offset="55.03%"></stop>
                <stop stop-color="#FF0000" offset="58.22%"></stop>
                <stop stop-color="#FF0000" offset="100%"></stop>
            </radialGradient>
            <radialGradient
                cx="2.552%"
                cy="14.432%"
                fx="2.552%"
                fy="14.432%"
                r="139.457%"
                gradientTransform="translate(0.025520,0.144320),scale(1.000000,0.649211),translate(-0.025520,-0.144320)"
                id="radialGradient-6"
            >
                <stop stop-color="#00B4E6" offset="0%"></stop>
                <stop stop-color="#00B0E3" offset="20.1%"></stop>
                <stop stop-color="#01A5DB" offset="38.98%"></stop>
                <stop stop-color="#0292CD" offset="57.37%"></stop>
                <stop stop-color="#0377BA" offset="75.46%"></stop>
                <stop stop-color="#0455A1" offset="93.16%"></stop>
                <stop stop-color="#054696" offset="100%"></stop>
            </radialGradient>
            <path
                d="M0,4 C-2.62343273e-16,1.85780461 1.68396847,0.108921124 3.80035966,0.00489530723 L4,0 L44,0 L44.1996403,0.00489530723 C46.3160315,0.108921124 48,1.85780461 48,4 L48,28 C48,30.1421954 46.3160315,31.8910789 44.1996403,31.9951047 L44,32 L4,32 L3.80035966,31.9951047 C1.68396847,31.8910789 -2.62343273e-16,30.1421954 0,28 L0,4 Z"
                id="path-7"
            ></path>
            <path
                d="M0,4 C-2.62343273e-16,1.85780461 1.68396847,0.108921124 3.80035966,0.00489530723 L4,0 L44,0 L44.1996403,0.00489530723 C46.3160315,0.108921124 48,1.85780461 48,4 L48,28 C48,30.1421954 46.3160315,31.8910789 44.1996403,31.9951047 L44,32 L4,32 L3.80035966,31.9951047 C1.68396847,31.8910789 -2.62343273e-16,30.1421954 0,28 L0,4 Z"
                id="path-9"
            ></path>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
                id="Desktop/2c.3b-Zusammenfassung"
                transform="translate(-1128.000000, -2412.000000)"
            >
                <g id="BG" fill="#000000" fill-rule="nonzero">
                    <g id="Color">
                        <rect x="0" y="0" width="1760" height="4612"></rect>
                    </g>
                </g>
                <g id="Content" transform="translate(355.000000, 70.000000)">
                    <g transform="translate(0.000000, 204.000000)" id="4">
                        <g transform="translate(2.000000, 2005.000000)">
                            <g
                                id="Radio-Buttons"
                                transform="translate(0.000000, 64.000000)"
                            >
                                <g
                                    id="2"
                                    transform="translate(543.000000, 0.000000)"
                                >
                                    <g id="BG">
                                        <mask id="mask-2" fill="white">
                                            <use xlink:href="#path-1"></use>
                                        </mask>
                                        <g id="Shadow" fill-rule="nonzero"></g>
                                        <g
                                            id="Color"
                                            mask="url(#mask-2)"
                                            fill-rule="nonzero"
                                        >
                                            <rect
                                                id="Border"
                                                x="0"
                                                y="0"
                                                width="503"
                                                height="141"
                                            ></rect>
                                        </g>
                                        <mask id="mask-4" fill="white">
                                            <use xlink:href="#path-3"></use>
                                        </mask>
                                        <path
                                            stroke="#4D4D4D"
                                            d="M491,0.5 C494.122714,0.5 496.954734,1.74462712 499.027024,3.76489021 C501.103274,5.78901439 502.416989,8.59170885 502.4962,11.7013339 L502.5,129 C502.5,132.122714 501.255373,134.954734 499.23511,137.027024 C497.210987,139.103272 494.408295,140.416987 491.298672,140.4962 L12.0061515,140.5 C8.76205654,140.419368 5.89334379,139.118048 3.83142408,137.056129 C1.75669988,134.981404 0.5,132.137424 0.5,129 L0.5,12 C0.5,8.87728649 1.74462712,6.04526612 3.76489021,3.97297641 C5.78901307,1.89672754 8.59170521,0.583012531 11.7013278,0.503799808 Z"
                                        ></path>
                                    </g>
                                    <g
                                        id="Cards"
                                        transform="translate(40.000000, 0.000000)"
                                    >
                                        <g
                                            transform="translate(188.000000, 69.000000)"
                                            id="Content"
                                        >
                                            <g
                                                id="Mask"
                                                fill="#000000"
                                                fill-opacity="0"
                                                fill-rule="nonzero"
                                            >
                                                <path
                                                    d="M0,4 C-2.62343273e-16,1.85780461 1.68396847,0.108921124 3.80035966,0.00489530723 L4,0 L44,0 L44.1996403,0.00489530723 C46.3160315,0.108921124 48,1.85780461 48,4 L48,28 C48,30.1421954 46.3160315,31.8910789 44.1996403,31.9951047 L44,32 L4,32 L3.80035966,31.9951047 C1.68396847,31.8910789 -2.62343273e-16,30.1421954 0,28 L0,4 Z"
                                                    id="BG"
                                                ></path>
                                            </g>
                                            <g id="Card" fill-rule="nonzero">
                                                <g>
                                                    <rect
                                                        id="BG"
                                                        fill="#000000"
                                                        x="0"
                                                        y="0"
                                                        width="48"
                                                        height="32"
                                                    ></rect>
                                                    <path
                                                        d="M33,21.0591148 C33,21.3450944 32.7990127,21.6991644 32.5578279,21.8353451 L23.9421721,26.8876509 C23.7009873,27.0374497 23.2990127,27.0374497 23.0578279,26.8876509 L14.4421721,21.8353451 C14.2009873,21.6855463 14,21.3450944 14,21.0591148 L14,10.9408852 C14,10.6549056 14.2009873,10.3008357 14.4421721,10.1646549 L23.0578279,5.11234912 C23.2990127,4.96255029 23.7009873,4.96255029 23.9421721,5.11234912 L32.5578279,10.1646549 C32.7990127,10.3144537 33,10.6549056 33,10.9408852 L33,21.0591148 Z"
                                                        id="Path"
                                                        fill="#FFFFFF"
                                                    ></path>
                                                    <path
                                                        d="M27.9753173,16.0340452 L25.7376587,19.3840916 L24.5853315,17.5865058 L25.9118477,15.5710306 C26.1530324,15.2169607 26.6889986,14.2228413 26.0726375,12.8746518 C25.5768688,11.7852058 24.5049365,11.2541009 23.5669958,11.2541009 C22.629055,11.2541009 21.5973202,11.7443516 21.061354,12.8746518 C20.4449929,14.168369 20.9809591,15.1897246 21.2087447,15.5301764 C21.2087447,15.5301764 21.9456982,16.6332405 22.5620592,17.5592696 L23.5669958,19.0300217 L25.067701,21.3587125 C25.0811001,21.3723305 25.3222849,21.7400186 25.7376587,21.7400186 C26.1396333,21.7400186 26.3808181,21.3723305 26.4210155,21.3314763 L29.9449929,16.0340452 L27.9753173,16.0340452 L27.9753173,16.0340452 Z M23.5669958,16.1157536 C23.5669958,16.1157536 22.977433,15.2033426 22.6022567,14.5769112 C22.1868829,13.8960074 22.6558533,12.8882699 23.5669958,12.8882699 C24.4915374,12.8882699 24.9471086,13.8960074 24.5317348,14.5769112 C24.1565585,15.2169607 23.5669958,16.1157536 23.5669958,16.1157536 Z"
                                                        id="Shape"
                                                        fill="url(#radialGradient-5)"
                                                    ></path>
                                                    <path
                                                        d="M21.3963329,19.275147 L19.1854725,16.1293717 C19.1854725,16.1293717 18.5959097,15.2169607 18.2207334,14.5905292 C17.8053597,13.9096255 18.27433,12.901888 19.1854725,12.901888 C19.3060649,12.901888 19.4132581,12.9155061 19.5070522,12.9427422 L20.2842031,11.4992262 C19.922426,11.3494274 19.5472496,11.267719 19.1854725,11.267719 C18.2475317,11.267719 17.2157969,11.7579697 16.6798307,12.8882699 C16.0634697,14.181987 16.5994358,15.2033426 16.8272214,15.5437945 L20.6861777,21.3587125 C20.712976,21.4131848 20.9675599,21.7808728 21.3695346,21.7808728 C21.7849083,21.7808728 22.0126939,21.4268028 22.0528914,21.3723305 L23.2186178,19.5747447 L22.2136812,18.0767564 L21.3963329,19.275147 Z"
                                                        id="Path"
                                                        fill="url(#radialGradient-6)"
                                                    ></path>
                                                </g>
                                            </g>
                                            <g id="BG">
                                                <mask id="mask-8" fill="white">
                                                    <use
                                                        xlink:href="#path-7"
                                                    ></use>
                                                </mask>
                                                <g
                                                    id="Shadow"
                                                    fill-rule="nonzero"
                                                ></g>
                                                <g
                                                    id="Color"
                                                    mask="url(#mask-8)"
                                                    fill-rule="nonzero"
                                                >
                                                    <rect
                                                        x="0"
                                                        y="0"
                                                        width="48"
                                                        height="32"
                                                    ></rect>
                                                </g>
                                                <mask id="mask-10" fill="white">
                                                    <use
                                                        xlink:href="#path-9"
                                                    ></use>
                                                </mask>
                                                <path
                                                    stroke="#4D4D4D"
                                                    d="M43.9879567,0.50000147 C44.9982231,0.550202448 45.8740341,0.950060363 46.4990062,1.57503245 C47.127514,2.20354027 47.5,3.05834036 47.5,4 L47.5,28 C47.5,28.9348303 47.1335175,29.7840737 46.5363457,30.4118469 C45.9387961,31.0400173 45.1103329,31.4466188 44.1869243,31.4951062 L4.01204333,31.4999985 C3.0017769,31.4497976 2.12596592,31.0499396 1.50099384,30.4249676 C0.872486018,29.7964597 0.5,28.9416596 0.5,28 L0.5,4 C0.5,3.06516969 0.866482481,2.21592627 1.46365426,1.58815311 C2.06120392,0.959982707 2.88966707,0.553381167 3.81307568,0.504893762 Z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
