<template>
    <svg
        width="48px"
        height="32px"
        viewBox="0 0 48 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M0,12 C-7.99137971e-16,5.47454326 5.20854437,0.165607248 11.6950252,0.00379985776 L12,0 L491,0 C497.525457,-1.19870696e-15 502.834393,5.20854437 502.9962,11.6950252 L503,12 L503,129 C503,135.525457 497.791456,140.834393 491.304975,140.9962 L491,141 L12,141 L11.6950252,140.9962 C5.20854437,140.834393 0,135.525457 0,129 L0,12 Z"
                id="path-1"
            ></path>
            <path
                d="M0,12 C-7.99137971e-16,5.47454326 5.20854437,0.165607248 11.6950252,0.00379985776 L12,0 L491,0 C497.525457,-1.19870696e-15 502.834393,5.20854437 502.9962,11.6950252 L503,12 L503,129 C503,135.525457 497.791456,140.834393 491.304975,140.9962 L491,141 L12,141 L11.6950252,140.9962 C5.20854437,140.834393 0,135.525457 0,129 L0,12 Z"
                id="path-3"
            ></path>
            <path
                d="M0,4 C-2.62343273e-16,1.85780461 1.68396847,0.108921124 3.80035966,0.00489530723 L4,0 L44,0 L44.1996403,0.00489530723 C46.3160315,0.108921124 48,1.85780461 48,4 L48,28 C48,30.1421954 46.3160315,31.8910789 44.1996403,31.9951047 L44,32 L4,32 L3.80035966,31.9951047 C1.68396847,31.8910789 -2.62343273e-16,30.1421954 0,28 L0,4 Z"
                id="path-5"
            ></path>
            <path
                d="M0,4 C-2.62343273e-16,1.85780461 1.68396847,0.108921124 3.80035966,0.00489530723 L4,0 L44,0 L44.1996403,0.00489530723 C46.3160315,0.108921124 48,1.85780461 48,4 L48,28 C48,30.1421954 46.3160315,31.8910789 44.1996403,31.9951047 L44,32 L4,32 L3.80035966,31.9951047 C1.68396847,31.8910789 -2.62343273e-16,30.1421954 0,28 L0,4 Z"
                id="path-7"
            ></path>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
                id="Desktop/2c.3b-Zusammenfassung"
                transform="translate(-556.000000, -2412.000000)"
            >
                <g id="BG" fill="#000000" fill-rule="nonzero">
                    <g id="Color">
                        <rect x="0" y="0" width="1760" height="4612"></rect>
                    </g>
                </g>
                <g id="Content" transform="translate(355.000000, 70.000000)">
                    <g transform="translate(0.000000, 204.000000)" id="4">
                        <g transform="translate(2.000000, 2005.000000)">
                            <g
                                id="1"
                                transform="translate(0.000000, 64.000000)"
                            >
                                <g id="BG">
                                    <mask id="mask-2" fill="white">
                                        <use xlink:href="#path-1"></use>
                                    </mask>
                                    <g id="Shadow" fill-rule="nonzero"></g>
                                    <g
                                        id="Color"
                                        mask="url(#mask-2)"
                                        fill-rule="nonzero"
                                    >
                                        <rect
                                            id="Border"
                                            x="0"
                                            y="0"
                                            width="503"
                                            height="141"
                                        ></rect>
                                    </g>
                                    <mask id="mask-4" fill="white">
                                        <use xlink:href="#path-3"></use>
                                    </mask>
                                    <path
                                        stroke="#CCCCCC"
                                        stroke-width="2"
                                        d="M491,1 C493.986927,1 496.69581,2.19049111 498.677993,4.12290923 C500.662514,6.05760759 501.918824,8.73591848 501.996201,11.7077789 L502,129 C502,131.986927 500.809509,134.69581 498.877091,136.677993 C496.942395,138.662512 494.264089,139.918821 491.292233,139.9962 L12.0124243,140 C8.90694877,139.921261 6.15953481,138.677133 4.18497747,136.702575 C2.20130517,134.718903 1,131.999668 1,129 L1,12 C1,9.01307283 2.19049111,6.30418956 4.12290923,4.32200705 C6.05760499,2.33748823 8.73591129,1.08117946 11.707767,1.00379976 Z"
                                    ></path>
                                </g>
                                <g
                                    id="Cards"
                                    transform="translate(40.000000, 0.000000)"
                                >
                                    <g
                                        transform="translate(0.000000, 40.000000)"
                                    >
                                        <g
                                            transform="translate(159.000000, 29.000000)"
                                            id="Content"
                                        >
                                            <g
                                                id="Mask"
                                                fill="#000000"
                                                fill-opacity="0"
                                                fill-rule="nonzero"
                                            >
                                                <path
                                                    d="M0,4 C-2.62343273e-16,1.85780461 1.68396847,0.108921124 3.80035966,0.00489530723 L4,0 L44,0 L44.1996403,0.00489530723 C46.3160315,0.108921124 48,1.85780461 48,4 L48,28 C48,30.1421954 46.3160315,31.8910789 44.1996403,31.9951047 L44,32 L4,32 L3.80035966,31.9951047 C1.68396847,31.8910789 -2.62343273e-16,30.1421954 0,28 L0,4 Z"
                                                    id="BG"
                                                ></path>
                                            </g>
                                            <g id="Card" fill-rule="nonzero">
                                                <g>
                                                    <rect
                                                        id="BG"
                                                        fill="#FFFFFF"
                                                        x="0"
                                                        y="0"
                                                        width="48"
                                                        height="32"
                                                    ></rect>
                                                    <polygon
                                                        id="Fill-2"
                                                        fill="#FF5F00"
                                                        points="20.0054126 23.4293994 28.2837206 23.4293994 28.2837206 8.55314849 20.0054126 8.55314849"
                                                    ></polygon>
                                                    <path
                                                        d="M20.5310194,15.9915367 C20.5310194,12.9735022 21.9441135,10.2855487 24.1445666,8.55314849 C22.5354212,7.28643596 20.5047391,6.5306133 18.2977159,6.5306133 C13.0729209,6.5306133 8.83758089,10.7662162 8.83758089,15.9915367 C8.83758089,21.2165945 13.0729209,25.4521973 18.2977159,25.4521973 C20.5047391,25.4521973 22.5354212,24.6963747 24.1445666,23.4296622 C21.9441135,21.697262 20.5310194,19.0093085 20.5310194,15.9915367"
                                                        id="Fill-3"
                                                        fill="#EB001B"
                                                    ></path>
                                                    <path
                                                        d="M39.4515522,15.9915367 C39.4515522,21.2165945 35.2162122,25.4521973 29.9914172,25.4521973 C27.784394,25.4521973 25.753712,24.6963747 24.1445666,23.4296622 C26.3450197,21.697262 27.7581137,19.0093085 27.7581137,15.9915367 C27.7581137,12.9735022 26.3450197,10.2855487 24.1445666,8.55314849 C25.753712,7.28643596 27.784394,6.5306133 29.9914172,6.5306133 C35.2162122,6.5306133 39.4515522,10.7662162 39.4515522,15.9915367"
                                                        id="Fill-5"
                                                        fill="#F79E1B"
                                                    ></path>
                                                </g>
                                            </g>
                                            <g id="BG">
                                                <mask id="mask-6" fill="white">
                                                    <use
                                                        xlink:href="#path-5"
                                                    ></use>
                                                </mask>
                                                <g
                                                    id="Shadow"
                                                    fill-rule="nonzero"
                                                ></g>
                                                <g
                                                    id="Color"
                                                    mask="url(#mask-6)"
                                                    fill-rule="nonzero"
                                                >
                                                    <rect
                                                        x="0"
                                                        y="0"
                                                        width="48"
                                                        height="32"
                                                    ></rect>
                                                </g>
                                                <mask id="mask-8" fill="white">
                                                    <use
                                                        xlink:href="#path-7"
                                                    ></use>
                                                </mask>
                                                <path
                                                    stroke="#4D4D4D"
                                                    d="M43.9879567,0.50000147 C44.9982231,0.550202448 45.8740341,0.950060363 46.4990062,1.57503245 C47.127514,2.20354027 47.5,3.05834036 47.5,4 L47.5,28 C47.5,28.9348303 47.1335175,29.7840737 46.5363457,30.4118469 C45.9387961,31.0400173 45.1103329,31.4466188 44.1869243,31.4951062 L4.01204333,31.4999985 C3.0017769,31.4497976 2.12596592,31.0499396 1.50099384,30.4249676 C0.872486018,29.7964597 0.5,28.9416596 0.5,28 L0.5,4 C0.5,3.06516969 0.866482481,2.21592627 1.46365426,1.58815311 C2.06120392,0.959982707 2.88966707,0.553381167 3.81307568,0.504893762 Z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
